import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import Layout from "../../components/layout"
import Text from "../../components/text"
import { Input, Loading, TextArea, Button } from "../../components/form"
import { space } from "../../theme"

const Wrapper = styled.div`
  display: grid;
`

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${space("normal")};
  margin: 0 auto;
  padding: ${space("large")} 0;
  -webkit-font-smoothing: antialiased;
  ${up("tablet")} {
    grid-template-columns: 768px;
  }
  ${up("desktop")} {
    grid-template-columns: 960px;
  }
`

const F = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  justify-items: flex-start;
`

const SubmitBtn = styled(Button)`
  justify-self: flex-end;
`

const getData = (target: HTMLFormElement) => ({
  type: "recovery",
  firstname: target.firstname.value,
  lastname: target.lastname.value,
  email: target.email.value,
  merchant_name: target.merchant_name.value,
  partner_name: target.partner_name.value,
  phonenumber: target.phonenumber.value,
  dob: target.dob.value,
  sortcode: target.sortcode.value,
  accountnumber: target.accountnumber.value,
  postcode: target.postcode.value,
  amount: target.amount.value,
  lastactivity: target.lastactivity.value,
  message: target.message.value,
})

const Recovery = () => {
  const [loading, setLoading] = React.useState(false)
  const onsubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const data = getData(e.currentTarget)
    setLoading(true)
    fetch("/api/form", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then(r => r.json())
      .then(json => {
        setLoading(false)
      })
    e.preventDefault()
  }
  return (
    <Layout>
      <Wrapper>
        <Container>
          <Text type="headline" weight="bold" align="center">
            Emoney Recovery Form
          </Text>
          <Text type="h4" align="center" color="grey">
            Please provide as much information as possible to help us resolve
            the issue in a timely manner.
          </Text>
          <F onSubmit={onsubmit}>
            <input type="hidden" name="type" value="recovery" />
            <Input
              type="text"
              name="firstname"
              required={true}
              placeholder="First name (required)"
            />
            <Input
              type="text"
              name="lastname"
              required={true}
              placeholder="Last name (required)"
            />
            <Input
              type="text"
              name="email"
              required={true}
              placeholder="Email address (required)"
            />
            <Input
              type="text"
              name="merchant_name"
              placeholder="Merchant name (if applicable)"
            />
            <Input
              type="text"
              name="partner_name"
              placeholder="Partner name (if applicable)"
            />
            <Input type="text" name="phonenumber" placeholder="Phone number" />
            <Input
              type="text"
              name="dob"
              placeholder="Date of birth (dd/mm/yyyy)"
            />
            <Input
              type="text"
              name="sortcode"
              placeholder="Sort code (xx-xx-xx)"
            />
            <Input
              type="text"
              name="accountnumber"
              placeholder="Account number"
            />
            <Input type="text" name="postcode" placeholder="Postcode" />
            <Input type="text" name="amount" placeholder="Amount to recover" />
            <Input
              type="text"
              name="lastactivity"
              placeholder="Month and year of last activity (mm/yyyy)"
            />
            <TextArea
              name="message"
              placeholder="Additional details"
              rows={10}
            ></TextArea>
            <SubmitBtn disabled={loading} type="submit">
              {loading ? <Loading /> : "Send"}
            </SubmitBtn>
          </F>
        </Container>
      </Wrapper>
    </Layout>
  )
}
export default Recovery
